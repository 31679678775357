<template>
  <iframe
    ref="player"
    :key="fixedUrl"
    :src="fixedUrl"
    type="text/html"
    allow="autoplay"
    width="100%"
    height="100%"
    frameborder="0"
    loading="lazy"
    allowfullscreen
    allowtransparency
    crossorigin
    playsinline
    title="Youtube"
  ></iframe>
</template>

<script>
export const YT_REGEX = /(.*?)(^|\/|v=)([a-z0-9_-]{11})(.*)?/i // eslint-disable-line

export default {
  name: 'YoutubePlayer',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      required: true
    }
  },
  async mounted () {
    await this.$nextTick()
    this.$refs.player.addEventListener('load', this.onLoad)
  },
  computed: {
    fixedUrl () {
      const params = [`enablejsapi=1&playsinline=1&modestbranding=1&origin=${window.location.origin}`]
      const { loop, muted, autoplay, controls } = this.$attrs
      if (loop) params.push('loop=1')
      if (muted) params.push('muted=1')
      if (autoplay) params.push('autoplay=1')
      if (controls) params.push('controls=2')

      return `https://www.youtube.com/embed/${this.url.match(YT_REGEX)[3]}?${params.join('&')}`
    }
  },
  methods: {
    onLoad () {
      if (!window.YT || !document.getElementById('iframe-yt-script')) {
        const firstScriptTag = document.getElementsByTagName('script')[0]
        const tag = document.createElement('script')
        const callback = window.onYouTubeIframeAPIReady

        tag.id = 'iframe-yt-script'
        tag.src = 'https://www.youtube.com/iframe_api'
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

        // Set callback to process queue
        window.onYouTubeIframeAPIReady = () => {
        // Call global callback if set
          if (callback) {
            callback()
          }
          this.createPlayer()
        }
      } else {
        this.createPlayer()
      }
    },
    createPlayer () {
      if (!window.YT) return
      this.player = new window.YT.Player(this.$refs.player, {
        events: {
          onReady: () => this.$emit('on-ready'),
          onStateChange: (e) => this.onPlayerStateChange(e.data)
        }
      })
    },
    onPlayerStateChange (playerStatus) {
      if (playerStatus === -1) {
        // unstarted
      } else if (playerStatus === 0) {
        // ended
        this.$emit('ended')
      } else if (playerStatus === 1) {
        // playing
        this.$emit('playing')
      } else if (playerStatus === 2) {
        // paused
        this.$emit('pause')
      } else if (playerStatus === 3) {
        // buffering
      } else if (playerStatus === 5) {
        // video cued
      }
    },
    play () {
      this.player.playVideo()
    },
    pause () {
      this.player.pauseVideo()
    },
    mute () {
      this.player.mute()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
