<template>
  <iframe
    ref="player"
    :key="fixedUrl"
    :src="fixedUrl"
    type="text/html"
    width="100%"
    height="100%"
    frameborder="0"
    loading="lazy"
    allowfullscreen
    allowtransparency
    crossorigin
    playsinline
    title="Vimeo"
  ></iframe>
</template>

<script>
export const VIMEO_REGEX = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/ // eslint-disable-line

export default {
  name: 'VimeoPlayer',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    fixedUrl () {
      const params = ['playsinline=1']
      const { loop, muted, autoplay, controls } = this.$attrs
      if (loop) params.push('loop=1')
      if (muted) params.push('muted=1')
      if (autoplay) params.push('autoplay=1')
      if (controls) params.push('controls=1')

      return `https://player.vimeo.com/video/${this.url.match(VIMEO_REGEX)[3]}?${params.join('&')}`
    }
  },
  mounted () {
    if (!window.Vimeo || !document.getElementById('iframe-vimeo-script')) {
      const firstScriptTag = document.getElementsByTagName('script')[0]
      const tag = document.createElement('script')
      const callback = tag.onload

      tag.id = 'iframe-vimeo-script'
      tag.src = 'https://player.vimeo.com/api/player.js'
      tag.onload = () => {
        if (callback) {
          callback()
        }
        this.createPlayer()
      }

      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      this.createPlayer()
    }
  },
  beforeDestroy () {
    this.player && this.player.destroy()
  },
  methods: {
    createPlayer () {
      if (!this.$refs.player) return
      this.player = new window.Vimeo.Player(this.$refs.player)
      this.player.on('play', () => this.$emit('playing'))
      this.player.on('pause', () => this.$emit('pause'))
      this.player.on('ended', () => this.$emit('ended'))
    },
    play () {
      this.player.play()
    },
    pause () {
      this.player.pause()
    }
  }
}
</script>

<style>

</style>
