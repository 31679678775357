<template>
  <component
    ref="video"
    v-if="videoSettings"
    v-bind="$attrs"
    v-on="$listeners"
    :is="videoSettings.component"
    :url="videoSettings.url"
  />
</template>

<script>
import YoutubePlayer, { YT_REGEX } from '@/atoms/YoutubePlayer.vue'
import VimeoPlayer, { VIMEO_REGEX } from '@/atoms/VimeoPlayer.vue'

export default {
  name: 'VideoPlayer',
  inheritAttrs: false,
  props: {
    video: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    videoSettings () {
      const vimeo = this.video.match(VIMEO_REGEX)
      const youtube = this.video.match(YT_REGEX)
      if (vimeo && vimeo[3]) {
        return { component: VimeoPlayer, url: this.video }
      } else if (youtube && youtube[3]) {
        return { component: YoutubePlayer, url: this.video }
      }
      return null
    }
  },
  methods: {
    play () {
      this.$refs.video.play()
    },
    pause () {
      this.$refs.video.pause()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
